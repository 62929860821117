import request from '@/utils/request'


// 查询赛事-信息列表
export function listCompetition(query) {
  return request({
    url: '/platform/competition/list',
    method: 'get',
    params: query
  })
}

// 查询赛事-信息分页
export function pageCompetition(query) {
  return request({
    url: '/platform/competition/page',
    method: 'get',
    params: query
  })
}

// 查询赛事-信息详细
export function getCompetition(data) {
  return request({
    url: '/platform/competition/detail',
    method: 'get',
    params: data
  })
}

// 查询赛事-信息详细
export function getCompetitionExtend(data) {
  return request({
    url: '/platform/competition-extend/detail',
    method: 'get',
    params: data
  })
}
// 新增赛事-信息
export function addCompetition(data) {
  return request({
    url: '/platform/competition/add',
    method: 'post',
    data: data
  })
}

// 修改赛事-信息
export function updateCompetition(data) {
  return request({
    url: '/platform/competition/edit',
    method: 'post',
    data: data
  })
}

// 删除赛事-信息
export function delCompetition(data) {
  return request({
    url: '/platform/competition/delete',
    method: 'post',
    data: data
  })
}
