<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card>
      <detail-list title="赛事信息" style="width: 100%">
        <detail-list-item term="报名结束時間" style="width: 100%">{{ form.endSignTime }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="赛事结束時間" style="width: 100%">{{ form.endFinalTime }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="参赛费" style="width: 100%">{{ form.chargeIntegral == null ? 0 : form.chargeIntegral}}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="奖励人数" style="width: 100%">{{ form.awardNum }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="奖励說明" v-if="form.awardExplain" style="width: 100%">{{ form.awardExplain }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="奖励内容" v-if="form.awardContent" style="width: 100%">{{ form.awardContent }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="奖励視頻" v-if="form.awardVideo" style="width: 100%">
          <div class="imgbox margin-center" @click="showDetail(form.awardVideo)">
            <div class="videomask">
              <a-icon class="icon" type="play-circle" />
            </div>
            <img  style="width: 100px;height: 100px"  :src="form.awardVideo + '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt=""
                  class="response">
          </div>
        </detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="奖励图片" v-if="form.awardImage" style="width: 100%">
          <img :src="form.awardImage" style="width: 100px" alt="awardImage" />
        </detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
      </detail-list>
    </a-card>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <!-- <img alt="example" style="width: 100%" :src="previewImage" /> -->
      <video style="width: 100%" :src="previewImage" ref="myVideo" :poster="previewImage + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
    </a-modal>

  </a-drawer>
</template>

<script>
import { getCompetitionExtend } from '@/api/platform/competition'
import DetailList from '@/components/tools/DetailList'
import Video from '@/views/video/modules/Video'
const DetailListItem = DetailList.Item;

export default {
  name: 'CreateForm',
  props: {},
  components: {
    Video,
    DetailList,
    DetailListItem
  },
  data() {
    return {
      submitLoading: false,
      previewVisible: false,
      previewImage: '',
      formTitle: '',
      // 表单参数
      form: {
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {}
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    showDetail(video){
      this.previewImage = video;
      this.previewVisible = true;
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    handleCancel() {
      this.previewVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCompetitionExtend({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
  }
}
</script>
<style>
.ant-divider-horizontal {
  display: none;
}
</style>

<style lang="less">

.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 100px;
  height: 100px;
  margin: 0 4px 4px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  line-height: 100px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 25px !important;
    color: #fff !important;
  }
}
</style>